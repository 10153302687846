<template>
    <div>
        <b-modal v-model="showModal" :title=" 'Store list' " content-class="card " header-class="card-header"
            cancel-variant="danger" size="md" footer-class="border-0">
            <v-observer tag="form" ref="formPagination" class="m-md-0 mx-2  "
                @submit.prevent="fnResetStorePagination()">
                <div class="row">
                    <div class="col-12 ">
                        <div class="form-group animated fadeIn">
                            <label for=""> <i class="fa fa-keyboard mr-1 "></i> <span
                                    v-text=" $t('dashboard.stores.name') "> </span> </label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Keyword"
                                    v-model="storesPagination.name " @change=" fnResetStorePagination() ">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-primary" type="button"> <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 ">
                        <div class="form-group animated fadeIn">
                            <label for=""> <i class=" fa fa-shopping-bag mr-1  "></i> <span
                                    v-text=" $t('tables.ecommerce') "> </span> </label>
                            <select class=" form-control " v-model="storesPagination.ecommerce "
                                @change="fnResetStorePagination() ">
                                <option :value="null" v-text="$t('general.form.all')"> </option>
                                <option :value="ecommerce.id" v-for=" (ecommerce,index) in logoList " :key="index"
                                    v-text=" ecommerce.id "> </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 ">
                        <div class="form-group animated fadeIn">
                            <label for=""> <i class="fa fa-circle mr-1 "></i> <span
                                    v-text=" $t('dashboard.stores.status') "> </span> </label>
                            <select class=" form-control " v-model="storesPagination.active "
                                @change="fnResetStorePagination() ">
                                <option :value="null" v-text="$t('general.form.all')"> </option>
                                <option :value="true"> Online </option>
                                <option :value="false"> Offline </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-lg-2 col-xl-1 d-none  ">
                        <div class="form-group animated fadeIn">
                            <label for=""> <i class="fa fa-arrows-v"></i> Limit </label>
                            <select class=" form-control " v-model="storesPagination.limit "
                                @change="fnResetStorePagination(); ">
                                <option :value="limit" v-for=" (limit,index) in CONST.PAGINATION " :key="index"
                                    v-text=" limit "> </option>
                            </select>
                        </div>
                    </div>
                </div>
            </v-observer>

            <div class=" table-scroll animated fadeIn " v-show=" storeList.length > 0 ">

                <table class=" table table-hover table-sm table-striped ">
                    <thead class="text-uppercase ">
                        <tr>
                            <th></th>
                            <th class="w-100">Store </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" (store,index) in storeList " :key="index">
                            <td>
                                <input type="checkbox" @change="fnEmitStoreList" class="mt-1" :value=" store.id "
                                    v-model=" tempStoreList " :id=' store.id '>
                            </td>
                            <td>
                                <label :for=' store.id ' class=" m-0 w-100 ">
                                    <div class="row">
                                        <div class="col-1">
                                            <img v-image=" fnGetEcommerceLogo( store.ecommerce ) " :alt="store.ecommerce"
                                                class=" img-20 " v-if=" fnGetEcommerceLogo( store.ecommerce ) != null">
                                            <img src="@/assets/images/no-image.jpg" class="img-fluid img-20 " alt=""
                                                v-else>
                                        </div>
                                        <div class="col-10">
                                            <p class="m-0 text-break" :class=" store.name ? '': 'text-uppercase'  "
                                                v-text=" store.name ? store.name : fnStoreURL(store.url) "></p>
                                        </div>
                                    </div>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <no-data :dataMessage="$t('noDataMessages.storeList')" v-show=" storeList.length == 0 " :isLoading="isLoading"
                :showBtn="false"> </no-data>

            <b-pagination class="animated fadeIn" v-model="storesPagination.page" :total-rows="storesPagination.total"
                :per-page="storesPagination.limit" @input="fnApiGetStores" align="fill" :first-text="$t('general.button.first')"
                :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')" :last-text="$t('general.button.last')"
                v-show="storesPagination.total > 0 && ( (storesPagination.total / storesPagination.limit) > 1 ) ">
            </b-pagination>

            <template v-slot:modal-footer>
                <div class="d-flex jusify-content-end ">
                    <button class="btn btn-primary " v-text=" 'Done'" @click=" showModal = false "> </button>
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import {
        mapState,
        mapMutations,
        mapActions,
        mapGetters,
    } from "vuex";

    export default {
        props: {
            showModalStores: {
                type: Boolean,
                default: false,
            },
            stores: {
                type: Array,
                required: true,
            }
        },
        watch: {
            stores() {
                this.tempStoreList = this.stores;
            },
            showModalStores() {
                this.showModal = true;
            },
            // tempStoreList(){
            //     this.$emit( 'update-list', this.$lodash.clone( this.tempStoreList ) );
            // }
        },

        data() {
            return ({
                showModal: false,
                isLoading: false,
                storesPagination: {
                    name: null,
                    ecommerce: null,
                    active: null,
                    page: 1,
                    limit: 5,
                    total: null,
                },
                storeList: [],
                tempStoreList: [],
                // logoList:[],
            })
        },
        computed: {
            ...mapState('stores', ['logoList']),
        },
        methods: {
            ...mapActions('stores', ['fnApiGetLogos']),

            async fnApiGetStores() {
                this.isLoading = true

                this.storeList = [];
                let {
                    name,
                    ecommerce,
                    active,
                    limit,
                    page
                } = this.$lodash.clone(this.storesPagination);
                let tempPagination = {
                    page,
                    limit
                };
                if (name != null && name != '') {
                    tempPagination.name = name
                };
                if (ecommerce != null && ecommerce != '') {
                    tempPagination.ecommerce = ecommerce
                };
                if (active !== null && active !== '') {
                    tempPagination.active = active
                };
                await axios.get('stores', {
                    params: tempPagination
                }).then((response) => {
                    this.storeList = response.data;
                }).catch();
                this.isLoading = false;
            },
            fnApiGetCountStores() {
                let {
                    name,
                    ecommerce,
                    active
                } = this.$lodash.cloneDeep(this.storesPagination);
                let tempPagination = {};
                if (name != null && name != '') {
                    tempPagination.name = name
                };
                if (ecommerce != null && ecommerce != '') {
                    tempPagination.ecommerce = ecommerce
                };
                if (active !== null && active !== '') {
                    tempPagination.active = active
                };
                axios.get('stores/count', {
                    params: tempPagination
                }).then((response) => {
                    this.storesPagination.total = response.data.count;
                }).catch((error) => {});
            },
            fnResetStorePagination() {
                this.storesPagination.page = 1;
                this.fnApiGetCountStores();
                this.fnApiGetStores();
            },
            fnFirstStorePagination() {
                this.storesPagination.page = 1;
                this.storesPagination.active = null;
                this.storesPagination.name = null;
                this.storesPagination.ecommerce = null;
                this.storesPagination.limit = this.CONST.PAGINATION[0];
                this.fnApiGetCountStores();
                this.fnApiGetStores();
            },
            fnApiGetStoreEcommerces() {
                axios.get('stores/ecommerces').then((response) => {
                    this.logoList = this.$lodash.sortBy(response.data, ['id']);
                }).catch();
            },

            fnEmitStoreList() {
                this.$emit('update-list', this.$lodash.clone(this.tempStoreList));
            }

        },
        mounted() {
            this.fnApiGetLogos();
            this.fnApiGetStoreEcommerces();
            this.fnFirstStorePagination();
        }
    }
</script>

<style>

</style>